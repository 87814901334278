import StateHelper from 'helpers/StateHelper';
import Report from 'factories/Report';

import { REPORTS_FETCHED, REPORT_FETCHED, MEMBER_GROUP_REPORTS_FETCHED } from 'actions/b2b/reports';

const stateHelper = new StateHelper(Report);
export const { initialState } = stateHelper;
const reducers = {};

reducers[REPORT_FETCHED] = stateHelper.set;
reducers[REPORTS_FETCHED] = stateHelper.set;
reducers[MEMBER_GROUP_REPORTS_FETCHED] = stateHelper.set;


export default stateHelper.createReducer(reducers);
