import Factory from 'factories/_Factory';

export default class MemberGroupMember extends Factory {
  static jsonType = 'member_group_member';

  static get defaults() {
    return {
      email: '',
      first_name: '',
      last_name: ''
    };
  }
}
