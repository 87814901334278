import { combineReducers } from 'redux';

import assignments from './assignments';
import classReports from './classReports';
import cme from './cme';
import loading from './loading';
import loadedAt from './loadedAt';
import modal from './modal';
import notifications from './notifications';
import organizationalMemberships from './organizationalMemberships';
import organizationalSubscriptions from './organizationalSubscriptions';
import organizations from './organizations';
import productPages from './productPages';
import questionBanks from './questionBanks';
import questions from './questions';
import quizzes from './quizzes';
import session from './session';
import staticData from './staticData';
import subjects from './subjects';
import subscriptions from './subscriptions';
import users from './users';
import patientTabGroups from './patientTabGroups';
import products from './products';
import practiceExamTemplates from './practiceExamTemplates';
import responses from './responses';
import reports from './reports';
import riskAssessment from './riskAssessment';
import performanceAllStudents from './performanceAllStudents';
import performanceOrgPeers from './performanceOrgPeers';
import performancePeers from './performancePeers';
import performanceQuestions from './performanceQuestions';
import performanceResponses from './performanceResponses';
import addonAccessTrackers from './addonAccessTrackers';
import memberGroups from './memberGroups';
import memberGroupMembers from './memberGroupMembers';
import examTemplates from './examTemplates';
import staffOrganizations from './staffOrganizations';
import clinicalPearls from './clinicalPearls';
import categories from './categories';
import boardExamDates from './boardExamDates';

const reducers = {
  assignments,
  classReports,
  loading,
  loadedAt,
  modal,
  responses,
  notifications,
  organizationalMemberships,
  organizationalSubscriptions,
  organizations,
  productPages,
  questionBanks,
  questions,
  quizzes,
  session,
  staticData,
  subjects,
  subscriptions,
  users,
  patientTabGroups,
  products,
  practiceExamTemplates,
  reports,
  riskAssessment,
  performanceAllStudents,
  performanceOrgPeers,
  performancePeers,
  performanceQuestions,
  performanceResponses,
  addonAccessTrackers,
  ...cme,
  memberGroups,
  memberGroupMembers,
  examTemplates,
  staffOrganizations,
  clinicalPearls,
  categories,
  boardExamDates,
};

export default combineReducers(reducers);
