import Factory from 'factories/_Factory';

export default class Report extends Factory {
  static jsonType = 'report';

  static get defaults() {
    return {
      organization_id: null,
      organization_name: "",
      generated_on: null,
      expires_on: null,
      start_date: null,
      end_date: null,
      report_data: {},
      member_group_id: null
    };
  }
}
