/* eslint react/button-has-type: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SVGIcon from 'components/SVGIcon';
import { svgProps } from 'components/icons/SvgContainer';

// StyleGuide = elements/inputs/button

class Button extends React.Component {
  render() {
    const {
      type,
      styleVariant,
      className,
      onClick,
      onAnimationEnd,
      disabled,
      children,
      active,
      large,
      small,
      icon,
      iconProps,
      switchRole,
      checked,
      linkTo,
      select,
      toggle,
      pressed,
      checkbox,
      draggable,
      onDragStart,
      ariaLabel,
      ariaControls,
      ariaExpanded,
      ariaHaspopup,
      ariaActivedescendant,
      ariaDisabled,
      role,
      selectIconClass,
      selectMenuOpen,
      caretToRight,
      truncateText,
      tabIndex
    } = this.props;

    let buttonSize = '';
    let switchProps = {};
    let dropProps = {};
    let toggleProps = {};
    let extraProps = {};
    if (large && !small) buttonSize = 'btn-lg';
    if (small && !large) buttonSize = 'btn-sm';
    if (switchRole || checkbox) {
      switchProps = {
        role: switchRole ? 'switch' : 'checkbox',
        'aria-checked': checked
      };
    }
    if (ariaControls) {
      dropProps = {
        'aria-controls': ariaControls,
        'aria-expanded': ariaExpanded
      };
    }
    if (toggle) {
      toggleProps = {
        'aria-pressed': pressed
      };
    }
    if (draggable) {
      extraProps = {
        draggable: draggable
      };
    }
    // TODO: Make icons fit better on all button sizes

    const finalProps = {
      className: `Button btn btn-${styleVariant} ${
        active ? 'active' : ''
      } ${buttonSize} ${className}`,
      onClick: ev => onClick(ev),
      disabled: disabled,
      onAnimationEnd: ev => onAnimationEnd(ev),
      onDragStart: ev => onDragStart(ev),
      ...(ariaLabel && { 'aria-label': ariaLabel }),
      ...(ariaHaspopup && { 'aria-haspopup': ariaHaspopup }),
      ...(ariaActivedescendant && { 'aria-activedescendant': ariaActivedescendant }),
      ...((ariaDisabled !== null) && { 'aria-disabled': ariaDisabled }),
      ...(role && { role }),
      ...switchProps,
      ...toggleProps,
      ...dropProps,
      ...extraProps
    };

    let finalChildren = styleVariant === 'fred' ? (
      <>
        {icon && (
        <div>
          <SVGIcon name={icon} {...iconProps} />
        </div>
        )}
        <span className="text-white">{children}</span>
      </>
    ) : (
      <>
        {icon && <SVGIcon name={icon} {...iconProps} />}
        <span className={`${caretToRight ? ' d-flex align-items-center' : ''}${truncateText ? ' overflow-hidden' : ''}`}>
          {children}
        </span>
        {select && (
        <div className="svg-div pl-3">
          <SVGIcon
            className={`ml-auto${selectIconClass ? ` ${selectIconClass}` : ''}${selectMenuOpen ? ' menu-open' : ''}`}
            name={typeof select === 'string' ? select : 'DownCaret3'}
            width={12}
            height={12}
            ariaHidden="true"
          />
        </div>
        )}
      </>
    );

    if (styleVariant !== 'fred' && (icon || select)) {
      finalChildren = (
        <div className="d-flex align-items-center btn-label">
          {finalChildren}
        </div>
      );
    }

    if (linkTo && !disabled) {
      return (
        <Link role="button" to={linkTo} {...finalProps}>
          {finalChildren}
        </Link>
      );
    }

    return (
      <button type={type} {...finalProps} tabIndex={tabIndex}>
        {finalChildren}
      </button>
    );
  }
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  styleVariant: PropTypes.oneOf([
    'primary',
    'light',
    'dark',
    'white',
    'outline-primary',
    'outline-light',
    'outline-dark',
    'success',
    'danger',
    'link',
    'none',
    'psi',
    'fred',
    'warning',
    'pearsonVue',
    'drag-drop-cloze'
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
  onAnimationEnd: PropTypes.func,
  icon: PropTypes.string,
  checked: PropTypes.bool,
  switchRole: PropTypes.bool,
  iconProps: PropTypes.shape({ ...svgProps }),
  linkTo: PropTypes.string,
  select: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  toggle: PropTypes.bool,
  pressed: PropTypes.bool,
  checkbox: PropTypes.bool,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
  ariaLabel: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  ariaHaspopup: PropTypes.string,
  ariaActivedescendant: PropTypes.string,
  ariaDisabled: PropTypes.bool,
  role: PropTypes.string,
  selectIconClass: PropTypes.string,
  selectMenuOpen: PropTypes.bool,
  caretToRight: PropTypes.bool,
  truncateText: PropTypes.bool,
  tabIndex: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  styleVariant: 'primary',
  className: '',
  onClick: () => {},
  onAnimationEnd: () => {},
  disabled: false,
  active: false,
  large: false,
  small: false,
  iconProps: { height: 16, width: 16 },
  toggle: false,
  pressed: false,
  checkbox: false,
  draggable: false,
  onDragStart: () => {},
  ariaLabel: null,
  ariaControls: "",
  ariaExpanded: false,
  ariaHaspopup: null,
  ariaActivedescendant: null,
  ariaDisabled: null,
  role: null,
  caretToRight: false,
  truncateText: false,
  tabIndex: '0'
};

export default Button;
