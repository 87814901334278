import StateHelper from 'helpers/StateHelper';
import MemberGroupMember from 'factories/MemberGroupMember';

import {
  SESSION_RESET
} from 'actions/session';
import {
  MEMBER_GROUP_FETCHED
} from 'actions/b2b/memberGroups';

const stateHelper = new StateHelper(MemberGroupMember);
export const { initialState } = stateHelper;
const reducers = {};

reducers[SESSION_RESET] = stateHelper.reset;
reducers[MEMBER_GROUP_FETCHED] = stateHelper.set;

export default stateHelper.createReducer(reducers);
