import React from 'react';
import PropTypes from 'prop-types';

export default function AriaLevelDiv({
  ariaLevel,
  isFocussable,
  className,
  children,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div {...(ariaLevel && { 'aria-level': ariaLevel })} className={className} tabIndex={isFocussable ? '0' : undefined}>
      {children}
    </div>
  );
}
AriaLevelDiv.propTypes = {
  ariaLevel: PropTypes.string,
  isFocussable: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};
